import React from 'react'
import PropTypes from 'prop-types'

import cardStyles from '../../styles/elements/card.module.scss'
import * as classnames from 'classnames'
import spacing, { spacingPropTypes } from '../../utils/spacing/spacing'

const Card = ({
  padding,
  margin,
  className,
  isTransparent,
  backgroundColor,
  hasShadow,
  children,
}) => {
  return (
    <div
      className={classnames(
        className,
        cardStyles.card,
        isTransparent && cardStyles.transparent,
        hasShadow && cardStyles.shadow,
        cardStyles[backgroundColor],
        spacing(padding, margin)
      )}
    >
      {children}
    </div>
  )
}

Card.defaultProps = {
  className: '',
  isTransparent: false,
  backgroundColor: 'white',
  hasShadow: true,
}

Card.propTypes = {
  ...spacingPropTypes,
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
  backgroundColor: PropTypes.oneOf(['white', 'primary', 'secondary']),
  hasShadow: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Card
