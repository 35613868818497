import React from 'react'
import PropTypes from 'prop-types'

import * as classnames from 'classnames'

import spacing, { spacingPropTypes } from '../../utils/spacing/spacing'

import dividerStyles from '../../styles/elements/divider.module.scss'

const Divider = ({ alignLeft, isFullWidth, isThin, padding, margin }) => {
  return (
    <hr
      className={classnames(
        dividerStyles.divider,
        alignLeft && dividerStyles.alignLeft,
        isFullWidth && dividerStyles.fullWidth,
        isThin && dividerStyles.thin,
        spacing(padding, margin)
      )}
    />
  )
}

Divider.defaultProps = {
  alignLeft: false,
  isFullWidth: false,
  isThin: false,
  margin: 'medium auto',
}

Divider.propTypes = {
  ...spacingPropTypes,
  alignLeft: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isThin: PropTypes.bool,
}

export default Divider
