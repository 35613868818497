import React from 'react'

import * as classnames from 'classnames'

import Grid from '@material-ui/core/Grid'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import SEO from '../../elements/SEO/SEO'
import Section from '../../components/Section/Section'
import Card from '../../elements/Card/Card'
import Heading from '../../elements/Heading/Heading'
import Text from '../../elements/Text/Text'
import Spacer from '../../elements/Spacer/Spacer'
import Divider from '../../elements/Divider/Divider'
import IconWrapper from '../../elements/IconWrapper/IconWrapper'

import spacing from '../../utils/spacing/spacing'

import { TrendingUp, Search, Web, Link } from '@material-ui/icons'
import formStyles from '../../styles/global/form.module.scss'
import buttonStyles from '../../styles/elements/button.module.scss'
import londonBackground from '../../images/backgrounds/london.jpg'
import connectionsBackground from '../../images/backgrounds/connections-2.jpg'

const HomePage = () => {
  return (
    <MainLayout isHeaderTransparent>
      <SEO title="Home" />
      <Section
        backgroundImage={londonBackground}
        isBackgroundFixed
        hasBackgroundOverlay
        alignText="center"
      >
        <Spacer padding="xLarge 0 large 0">
          <Grid container justify="center">
            <Grid item md={8}>
              <Heading level={1} color="white">
                enquire<strong>hub</strong> is a secure global business
                directory which helps businesses connect.
              </Heading>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item md={10}>
              <Text
                color="white"
                weight="light"
                letterSpacing={1}
                margin="small 0 medium"
              >
                We make finding business information simple. We help you
                generate enquiries and directly connect with businesses. enquire
                <strong>hub</strong> can take your business online hassle-free,
                saving you time and money. Our goal is to promote and grow your
                business for free!
              </Text>
            </Grid>
          </Grid>
        </Spacer>
      </Section>

      <Section alignText="center" backgroundColor="lightGrey">
        <Spacer padding="large 0">
          <Grid container justify="center">
            <Grid item md={10} lg={8}>
              <Heading level={2} weight="light" color="secondary">
                Coming soon...
              </Heading>
              <Divider />
              <Text margin="0 0 large">
                Signup to our mailing list below to be notified when we launch
              </Text>

              <Grid container justify="center">
                <Grid item xs={12} md={8}>
                  <div id="mc_embed_signup">
                    <form
                      action="https://enquirehub.us10.list-manage.com/subscribe/post?u=6868c07919d7e6f7ddd754e2c&amp;id=33674655bd"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="validate"
                      target="_blank"
                      noValidate
                    >
                      <div id="mc_embed_signup_scroll">
                        <Spacer margin="0 0 small">
                          <Text align="left" size="medium" margin="0 0 xxSmall">
                            <label htmlFor="mce-EMAIL">Email *</label>
                          </Text>
                          <input
                            type="email"
                            name="EMAIL"
                            className={classnames(
                              formStyles.text,
                              formStyles.block
                            )}
                            id="mce-EMAIL"
                            placeholder="Your email address"
                          />
                        </Spacer>
                        <Spacer margin="0 0 small">
                          <Text align="left" size="medium" margin="0 0 xxSmall">
                            <label htmlFor="mce-CNAME">Business name</label>
                          </Text>
                          <input
                            type="text"
                            name="CNAME"
                            className={classnames(
                              formStyles.text,
                              formStyles.block
                            )}
                            id="mce-CNAME"
                            placeholder="Your business name"
                          />
                        </Spacer>
                        <div id="mce-responses">
                          <div
                            className="response"
                            id="mce-error-response"
                            style={{ display: 'none' }}
                          />
                          <div
                            className="response"
                            id="mce-success-response"
                            style={{ display: 'none' }}
                          />
                        </div>
                        <div
                          style={{ position: 'absolute', left: -5000 }}
                          aria-hidden="true"
                        >
                          <input
                            type="text"
                            name="b_6868c07919d7e6f7ddd754e2c_33674655bd"
                            tabIndex={-1}
                          />
                        </div>
                        <input
                          type="submit"
                          value="Subscribe"
                          name="subscribe"
                          className={classnames(
                            buttonStyles.button,
                            buttonStyles.tertiary,
                            buttonStyles.block
                          )}
                        />
                      </div>
                    </form>
                  </div>
                  <script
                    type="text/javascript"
                    src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
                  />
                  <script type="text/javascript">
                    {/* eslint-disable-next-line */}
                    {`(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[5]='CNAME';ftypes[5]='text';}(jQuery));var $mcj = jQuery.noConflict(true);`}
                  </script>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Spacer>
      </Section>

      <Section
        backgroundImage={connectionsBackground}
        isBackgroundParallax
        alignText="center"
      >
        <Spacer padding="xLarge 0 xLarge 0">
          <Card isTransparent padding="0">
            <Spacer padding="large 0 large 0">
              <Grid container justify="center">
                <Grid
                  item
                  md={6}
                  lg={5}
                  className={classnames(spacing('small'))}
                >
                  <Card padding="small">
                    <IconWrapper
                      icon={TrendingUp}
                      align="center"
                      size="large"
                      element="span"
                      color="white"
                      hasBackground
                      backgroundColor="secondary"
                    />
                    <Heading
                      level={4}
                      color="secondary"
                      weight="regular"
                      margin="0"
                    >
                      Promote your business
                    </Heading>
                    <Divider />
                    <Text>
                      By joining enquire
                      <Text element="span" weight="bold">
                        hub
                      </Text>
                      , you can enhance your brands visibility, promoting your
                      business on a global scale at no charge.
                    </Text>
                  </Card>
                </Grid>

                <Grid
                  item
                  md={6}
                  lg={5}
                  className={classnames(spacing('small'))}
                >
                  <Card padding="small">
                    <IconWrapper
                      icon={Search}
                      align="center"
                      size="large"
                      element="span"
                      color="white"
                      hasBackground
                      backgroundColor="secondary"
                    />
                    <Heading
                      level={4}
                      color="secondary"
                      weight="regular"
                      margin="0"
                    >
                      Search and connect
                    </Heading>
                    <Divider />
                    <Text>
                      With enquire
                      <Text element="span" weight="bold">
                        hub
                      </Text>{' '}
                      you can find standardised business information easily. We
                      help you directly connect with businesses securely.
                    </Text>
                  </Card>
                </Grid>
              </Grid>

              <Grid container justify="center">
                <Grid
                  item
                  md={6}
                  lg={5}
                  className={classnames(spacing('small'))}
                >
                  <Card padding="small">
                    <IconWrapper
                      icon={Web}
                      align="center"
                      size="large"
                      element="span"
                      color="white"
                      hasBackground
                      backgroundColor="secondary"
                    />
                    <Heading
                      level={4}
                      color="secondary"
                      weight="regular"
                      margin="0"
                    >
                      Alternative to a website
                    </Heading>
                    <Divider />
                    <Text>
                      enquire
                      <Text element="span" weight="bold">
                        hub
                      </Text>{' '}
                      makes it easier to build an online presence for your
                      business compared to creating a website, which is often
                      time-consuming and expensive. It only takes a few clicks
                      to register and create your profile for free.
                    </Text>
                  </Card>
                </Grid>
                <Grid
                  item
                  md={6}
                  lg={5}
                  className={classnames(spacing('small'))}
                >
                  <Card padding="small">
                    <IconWrapper
                      icon={Link}
                      align="center"
                      size="large"
                      element="span"
                      color="white"
                      hasBackground
                      backgroundColor="secondary"
                    />
                    <Heading
                      level={4}
                      color="secondary"
                      weight="regular"
                      margin="0"
                    >
                      A premium backlink
                    </Heading>
                    <Divider />
                    <Text>
                      If you already run one, enquire
                      <Text element="span" weight="bold">
                        hub
                      </Text>{' '}
                      provides a direct free backlink to your website, improving
                      your SEO (Search Engine Optimisation) and increasing
                      rankings within search engines.
                    </Text>
                  </Card>
                </Grid>
              </Grid>
            </Spacer>
          </Card>
        </Spacer>
      </Section>
    </MainLayout>
  )
}

export default HomePage
