import React from 'react'
import PropTypes from 'prop-types'
import * as classnames from 'classnames'

import typographyStyles from '../../styles/global/typography.module.scss'
import iconWrapperStyles from '../../styles/elements/icon-wrapper.module.scss'

const IconWrapper = ({
  className,
  size,
  color,
  element,
  align,
  verticalAlign,
  hasBackground,
  backgroundColor,
  icon,
}) => {
  const Element = element
  const Icon = icon
  return (
    <Element
      className={classnames(
        className,
        typographyStyles[align],
        typographyStyles[color],
        iconWrapperStyles.iconWrapper,
        iconWrapperStyles[size],
        iconWrapperStyles[verticalAlign],
        hasBackground && iconWrapperStyles.hasBackground,
        hasBackground && iconWrapperStyles[backgroundColor]
      )}
    >
      <Icon />
    </Element>
  )
}

IconWrapper.defaultProps = {
  className: '',
  size: 'large',
  color: null,
  element: 'div',
  align: 'left',
  verticalAlign: 'middle',
  hasBackground: false,
  backgroundColor: null,
}

IconWrapper.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['xSmall', 'small', 'medium', 'large', 'xLarge']),
  color: PropTypes.oneOf(['white', 'primary', 'secondary']),
  element: PropTypes.oneOf(['div', 'span']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  verticalAlign: PropTypes.oneOf(['middle', 'sub', 'top']),
  hasBackground: PropTypes.bool,
  backgroundColor: PropTypes.oneOf(['white', 'primary', 'secondary']),
  icon: PropTypes.object.isRequired,
}

export default IconWrapper
